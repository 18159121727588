<template>
  <div class="live-event">
    <div class="event-inner">
      <div class="left">
        <div class="event-details">
          <a class="event-name" :href="fullURL" target="_blank">
            {{ event.name }}
          </a>

          <div class="more-info">
            <div class="event-date">{{ eventDate }}</div>
            <div class="event-date">Added By: {{ event.email }}</div>
            <div class="creation-date">Created on {{ creationDate }}</div>
          </div>
        </div>
      </div>
      <!-- end left -->
      <div class="right">
        <div class="tix-sold">
          <div class="tix-sold-number">{{ sold }}</div>
          <div class="tix-sold-label">Tickets Sold</div>
        </div>
        <a :href="fullURL" target="_blank">
          <button class="view-btn" type="button">View Event</button>
        </a>
        <div class="event-options">
          <span>Select Event Options</span>
          <event-options
            class="live-events-options"
            id="event-menu"
            :options="options"
            :eventUrl="event.url"
            :activated="event.userActivated"
            :cancelled="event.cancelled"
            :deactivateEligible="event.deactivateEligible"
            v-on:cancel="onCancel"
            v-on:redirect="redirect"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.live-event {
  margin: 0.75em auto;

  // border: 1px solid #333b3f;

  .event-inner {
    border-radius: 8px;
    box-shadow: 1px 1px 2px -1px #ffffff36, inset 0px 1px 5px 0px #00000069;
    // background-image: linear-gradient(#1f1f1f, #161616);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 52px;

    .left {
      // width: calc(100% - 400px);
      height: inherit;
      margin: 20px 0;

      .event-details {
        padding: 0 20px;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .event-name {
          color: var(--text);
          font-size: 1.75em;
          font-weight: 700;
          word-break: break-word;
          line-height: 1em;
        }

        .event-date {
          margin: 10px 0;
          font-weight: 600;
          text-transform: uppercase;

          font-size: 1.05em;
          line-height: 1em;
        }
        .creation-date {
          position: relative;
          // bottom: -48px;
          color: var(--primary-orange);
          font-size: 1.05em;
          font-weight: 600;
          text-transform: uppercase;
          line-height: 1em;
        }
      }
    }
    // end left
    .right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 380px;
      margin-left: 20px;

      @media screen and (max-width: 850px) {
        flex-direction: column;
        align-items: center;
      }

      .tix-sold {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 250px;
        padding: 5px 1px;
        margin: 10px 0;
        margin-bottom: 0;
        border: 1px solid var(--dashboard-border);
        border-radius: 10px;
        background-image: var(--dashboard-tiles);
        box-shadow: var(--dashboard-shadow);

        .tix-sold-number {
          margin-left: 10px;
          font-size: 3em;
          font-weight: 700;
          color: var(--primary-orange);
        }

        .tix-sold-label {
          padding: 5px 8px;
          // width: 75px;
          font-size: 15px;
          font-weight: 700;
          line-height: 100%;
          text-transform: uppercase;
        }
      }

      a {
        display: flex;
        width: 105px;
        margin: 10px;
        margin-bottom: 0;

        button {
          min-height: 50px;
          font-family: inherit;
          font-size: 1.25em;
          font-weight: 700;
          line-height: 1em;
          text-transform: uppercase;
          color: #ffffff;
          border: none;
          border-radius: 10px;
          background: var(--primary-orange);
          -webkit-appearance: none;
          outline: none;
          cursor: pointer;
        }

        @media screen and (max-width: 850px) {
          width: 100%;
          margin: 16px 0;
        }
      }

      &::v-deep .event-options {
        // color: #828282;
        /* padding-top: 3px; */
        background-image: var(--dropdown-background);
        // border: 1px solid var(--dropdown-border);
        box-shadow: var(--dashboard-shadow);
        width: 100%;
        align-items: center;
        border-radius: 10px;
        height: 50px;
        position: relative;
        display: flex;
        margin: 10px 10px 10px 0;

        span {
          width: 100%;
          height: fit-content;
          /* text-align: center; */
          /* line-height: 0px; */
          right: 50%;
          font-size: 17px;
          margin: 0 20px;
          // font-weight: bold;
        }

        .live-events-options {
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: initial;
          /* bottom: 35%; */
          pointer-events: none;

          .menu {
            // top: 20px;
            top: initial;
            height: 100%;
            position: relative;
            right: initial;
            width: 100%;

            &.active {
              transform: rotate(0deg);

              svg {
                transform: rotate(180deg);
              }
            }

            svg {
              transition: 0.5s;
              position: absolute;
              right: 15px;
              top: 14px;
            }
          }

          .options {
            .options-inner {
              width: 100%;
              top: 11px;
              right: 0px;
            }
          }
        }
        // margin-right: 10px;
        // // margin-bottom: -1em;
        // padding-top: 3px;
        // border: 1px solid white;
        // width: 350px;
        // height: 60px;

        .input-wrapper {
          margin-bottom: 1.75em;
        }
      }
      // end event-options
    }
    // end right
  }

  @media screen and (max-width: 780px) {
    .event-inner {
      max-width: min-content;

      .right {
        .tix-sold {
          width: 261px;
        }
        button {
          margin-right: 0;
        }
        .event-options {
          margin-right: 0;
        }
        margin-right: 20px;
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 456px) {
  .live-event {
    .event-inner {
      .right {
        .tix-sold {
          width: 100%;
        }
        button {
          margin-left: 0;
          width: 100%;
        }
      }
      .event-details {
        .event-name {
          font-size: 1em;
        }
        .event-date {
          font-size: 0.9em;
        }
        .creation-date {
          font-size: 0.7em;
        }
      }
    }
  }
}
</style>

<script>
import dateFormat from "dateformat";
import EventOptions from "@/components/EventOptions.vue";
import {
  faBuilding,
  faLink,
  faMoneyBill,
  faNewspaper
} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "live-event",
  components: {
    EventOptions
  },
  props: {
    event: Object
  },
  computed: {
    eventDate() {
      return dateFormat(
        +this.event.start.$date.$numberLong,
        "ddd, mmm dS, yyyy"
      );
    },
    eventEndDate() {
      return dateFormat(+this.event.end, "ddd, mmm dS, yyyy");
    },
    creationDate() {
      return dateFormat(+this.event.created, "ddd, mmm dS, yyyy");
    },
    sold() {
      return (this.event.promoter && this.event.promoter.tickets_sold) ? this.event.promoter.tickets_sold : 0;
    },
    fullURL() {
      if (this.event.url === "go-pro-academy") {
        return "https://goproacademy.com/?gpa=" + this.$store.state.user.pv_url;
      } else if (this.event.url === "go-pro-challenge") {
        return "https://mygoprochallenge.com/" + this.$store.state.user.pv_url;
      } else if (this.event.url === "wake-up-call-49974589893") {
        return "http://2023wakeup.com/?nmp=" + this.$store.state.user.pv_url;
      } else if (this.event.url === "wake-up-call-replay-68028721048") {
        return (
          "http://2023wakeup.com/replay?nmp=" + this.$store.state.user.pv_url
        );
      } else if (this.event.url === "go-pro-experience-nevada-19551963128") {
        return (
          "https://www.gopro2023.com/gpx-2023?nmp=" +
          this.$store.state.user.pv_url
        );
      } else if (this.event.cf_event_url) {
        return (
          `${this.event.cf_event_url}?nmp=` + this.$store.state.user.pv_url
        );
      } else {
        const pv = this.event.promoter._id.$oid;
        return this.createBuyersLink(
          this.event.url + (this.event.promoter.link ? "?pv=" + pv : ""),
          this.event.whitelabel
        );
      }
    },
    pageURL() {
      const pv = this.$store.state.user.pv_url;
      return this.createBuyersLink("pv/" + pv, this.event.whitelabel);
    }
  },
  data() {
    return {
      options: [
        {
          label: "Promo Buyers List",
          value: "list",
          icon: faMoneyBill,
          type: "1",
          link: "/event-buyers-list"
        },
        {
          label: "Financials",
          value: "financials",
          icon: faBuilding,
          type: "1",
          link: "/financials"
        },
        {
          label: "Promoter Link",
          value: "link",
          icon: faLink,
          type: "external",
          link: "#"
        },
        {
          label: "Promo Page",
          value: "page",
          icon: faNewspaper,
          type: "external",
          link: "#"
        },
        {
          label: "Sale lists",
          value: "sale-lists",
          icon: faNewspaper,
          type: "external",
          link: "#"
        }
      ]
    };
  },
  methods: {
    onCancel(url) {},
    redirect(val) {
      switch (val) {
        case "buy":
          window.open(this.fullURL, "_blank");
          break;
        case "link":
          this.$parent.$parent.$refs.promoterLinkModal.open(this.event);
          break;
        case "list":
          this.$router.push(`/event-buyers-list/${this.event.url}`);
          break;
        case "financials":
          this.$router.push(`/financials/${this.event.url}`);
          break;
        case "page":
          window.open(this.pageURL, "_blank");
          break;
        case "sale-lists":
          this.$router.push(`/sale-list/${this.event.url}`);
          break;
        default:
          console.error(`'${val}' is not yet implemented.`);
          break;
      }
    }
  }
};
</script>
